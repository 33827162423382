import "./about.styles.scss";

const About = () => {
  return (
    <div className="about">
      <div className="title">About</div>
      <div className="info">
        Hopefully this helps you manage your time with StackTV.
      </div>
    </div>
  );
};

export default About;
